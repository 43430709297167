<template>
      <div v-if="isMobile" class="container has-space">
        <MobileHeaderBack></MobileHeaderBack>
        <div class="mobile-account__container">
            <div class="signup-form__title">{{$t(title)}}</div>
            
            <!-- referral code -->
            <div class="mb-15rem">
                <div class="form-group">
                    <label class="form-label">{{$t('PHONE_NUMBER')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" ref="referralCodeId" readonly type="text" v-model="phoneNumber.inputPhoneNumber" v-on:keyup="inputReferralCodeEvent" :placeholder="$t('REFERRAL_CODE')">
                    </div>
                </div>       
            </div>  
        </div>
    </div>
    <AccountIndex v-else>
        <div class="account-content__wrapper">
            <div class="account-content__title tc">{{$t(title)}}</div>
          
            <div class="change-password__form-group">
                <!-- password -->
                <div class="form-group">
                    <label class="form-label">{{$t('PHONE_NUMBER')}}</label>
                    <div class="form-group__container">
                        <input class="form-control" ref="referralCodeId" readonly type="text" v-model="phoneNumber.inputPhoneNumber" v-on:keyup="inputReferralCodeEvent" :placeholder="$t('REFERRAL_CODE')">
                    </div>
                </div>
        
            </div>

        </div>
    </AccountIndex>
</template>

<script>
import MobileHeaderBack from '@/components/mobile/HeaderBack.vue'
import AccountIndex from '@/views/account/Index.vue'

import {mapGetters,mapActions} from 'vuex'
export default {
   components: {
        AccountIndex,
        MobileHeaderBack
    },
   data() {
       return {
            title: '',
            phoneNumber: {
                inputPhoneNumber: '',
                isSaveBtnEnable: false,
            }

       }
   },
   mounted() {
       this.init()
   },
    computed: {
        ...mapGetters([
            'isMobile',
            'currentLocale',
            'isLogin',
            "userInfo",
            "currentLanguageObj"
        ]),
    },
    methods: {
         init() {
            this.title = this.$route.meta.name;
            this.phoneNumber.inputPhoneNumber = this.userInfo.username;
        },
    },
}
</script>

<style>

</style>